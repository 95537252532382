import React from 'react'
import { Link } from 'gatsby'
import slug from 'slug'
import Layout from '../components/layout'
import tracks from '../components/tracks.json'

// lazy hacky page

// TODO consolidate this with other instance of slugify in gatsby-node
const slugify = str => slug(str, { lower: true })

const Info = () => (
  <Layout className='h-auto'>
    <h1 className='f4 f3-ns f2-m f1-l tracked ttu'>Links</h1>
    <p className='f3 lh-copy'>
      {tracks.map(t => {
        const trackSlug = slugify(t.trackName)

        return (<p key={trackSlug}>
          <Link
            className='black underline'
            to={`/${trackSlug}`}
          >
            {t.trackName}
          </Link>
        </p>)
      })}
    </p>
    <Link
      className='f5 f4-ns f3-m f2-l tracked ttu mv4 black no-underline'
      to='/'
    >
      Home
    </Link>
  </Layout>
)

export default Info
